<template>
  <div class="ss-tonnage">
    <div v-if="!isMobile" class="search-ton">
      <div class="ton-top">
        <el-card shadow="never" class="box-card">
          <el-form ref="emailcargoform" :model="emailcargoform" label-width="120px" :rules="rules" size="small">
            <div class="form-left">
              <el-row>
                <el-col :span="9">
                  <el-form-item :label="$t('message.cargo.cargoName')" prop="cargoName">
                    <el-autocomplete    v-model="emailcargoform.cargo_name" style="width: 100%;"
                                        :placeholder="$t('message.cargo.cargoName')"
                                        :fetch-suggestions="querySearchCargoName"
                                        :trigger-on-focus="false"
                                        @select="selectcargoname"
                                        @input="cargoName($event)"
                                        @change="reselectcargo"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="13">
                  <el-form-item :label="$t('message.cargo.volume')"  prop="quantity">
                    <el-col :span="5">
                      <el-form-item prop="quantityDown">
                        <el-input v-model.number="emailcargoform.quantityDown" type="number" :placeholder="$t('message.search.min')" @change="dwtDownchange" :min="0" oninput="if(value<0)value=0" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="1">--</el-col>
                    <el-col :span="5">
                      <el-form-item  prop="quantityUp">
                        <el-input v-model.number="emailcargoform.quantityUp" type="number" :placeholder="$t('message.search.max')" @change="dwtUpchange"  :min="0" oninput="if(value<0)value=0" />
                      </el-form-item>
                    </el-col>
                    <el-col id="col_2" :span="2">----</el-col>
                    <el-col :span="8">
                      <el-form-item prop="dwt_type">
                        <el-select v-model="dwt_type"
                                   :placeholder="$t('message.tct.please_select')"
                                   @change="typechange"
                                   prop="dwt_type">
                          <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="9">
                  <el-form-item :label="$t('message.cargo.loading_port')" prop="loadingportName">
                    <el-autocomplete v-model="emailcargoform.loadingportName" style="width: 100%;"
                                     :placeholder="$t('message.cargo.loading_port')"
                                     :trigger-on-focus="false"
                                     :fetch-suggestions="querySearchLoadPort"
                                     @select="selectloadport"
                                     @input="loadingPortName($event)"
                                     @change="reselectloadport"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="14">
                  <el-form-item :label="$t('message.cargo.start_end_date')" >
                    <el-col :span="9">
                      <el-form-item prop="laycanStart">
                        <el-date-picker type="date" :placeholder="$t('message.search.startdate')" v-model="emailcargoform.laycanStart" style="width: 100%;">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2">--</el-col>
                    <el-col :span="9">
                      <el-form-item prop="laycanEnd">
                        <el-date-picker type="date" :placeholder="$t('message.search.enddate')" v-model="emailcargoform.laycanEnd" style="width: 100%;">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="9">
                  <el-form-item :label="$t('message.cargo.dischargePort')" prop="dischargeportName">
                    <el-autocomplete v-model="emailcargoform.dischargeportName" style="width: 100%;"
                                     :placeholder="$t('message.cargo.dischargePort')"
                                     :trigger-on-focus="false"
                                     :fetch-suggestions="querySearchDischargePort"
                                     @select="selectdischargeport"
                                     @input="dischargePortName($event)"
                                     @change="reselectdischargeport"
                    >
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="10">
                  <el-form-item :label="$t('message.cargo.issuetime')" prop="sendDays">
                    <el-input :placeholder="$t('message.cargo.please_issuetime')"
                              v-model.number="emailcargoform.sendDays"
                              type='number'
                              style="width:100%"
                              :min="0" @input="sendDays"
                    >
                      <template #append>{{$t('message.withdays')}}</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="1"></el-col>
                <el-col :span="9">
                  <el-form-item>
                    <el-button class="search_btn"  type="primary" @click="getCargoEmail"  :disabled="searchdisabled">{{$t('message.cargo.search')}}</el-button>
                    <el-button @click="resetForm('emailcargoform')">{{$t('message.cargo.reset')}}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>

            </div>
            <div class="form-right"></div>
<!--            <div class="form-left">
              <el-row>
                <el-col :span="8">
                  <el-form-item  :label="$t('message.email.mailtheme')" label-width="100px">
                    <el-input v-model="emailcargoform.subject" :placeholder="$t('message.email.mailtheme')" style="width: 100%;">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="14">
                  <el-form-item :label="$t('message.email.sendtime')" label-width="100px">
                    <el-col :span="10" >
                      <el-form-item prop="send_time_before">
                        <el-date-picker type="date"
                                        :placeholder="$t('message.search.startdate')"
                                        v-model="emailcargoform.send_time_before"
                                        style="width: 100%;"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2">&#45;&#45;</el-col>
                    <el-col :span="10" >
                      <el-form-item prop="send_time_after">
                        <el-date-picker type="date"
                                        :placeholder="$t('message.search.enddate')"
                                        v-model="emailcargoform.send_time_after"
                                        style="width: 100%;"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item :label="$t('message.email.sender')"
                                prop="sendername" label-width="100px">
                    <el-input v-model="emailcargoform.sender"
                              :placeholder="$t('message.email.sender')"
                              style="width: 100%"
                              @input="senderEmail($event)"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('message.email.recipient')"
                                prop="Recipient" label-width="100px">
                    <el-input v-model="emailcargoform.receiver"
                              :placeholder="$t('message.email.recipient')"
                              style="width: 100%"
                              @input="recipient($event)"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button style="margin-left: 5%" type="primary" size="small" @click="getCargoEmail">{{$t('message.email.retrieval')}}</el-button>
                  <el-button style="margin-left: 5%" type="primary" size="small" @click="resetForm">{{$t('message.reset')}}</el-button>
                </el-col>
              </el-row>
            </div>-->
<!--            <div class="form-right">-->
<!--            </div>-->
          </el-form>
        </el-card>
      </div>
      <el-tabs class="result_list" v-model="activeName">
        <!--列表显示信息-->
        <!--tableData[index].id-->
        <el-tab-pane :label="$t('message.list')" name="table">
          <el-table
              #default="scope"
              :data="tableData" :border="false"
              style="width: 100%"
              @row-click="goDetails"
              :header-cell-style="{background:'#fff',color:'#515a6e','text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :show-overflow-tooltip="true">
<!--            <el-table-column type="index" width="50" />-->
            <el-table-column
                prop="cargo_name"
                :label="$t('message.cargo.cargoName')"
                min-width="60">
              <template  #default="scope">
                <span>{{scope.row.cargo_name}}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="quantity"
                :label="$t('message.cargo.volume')"
                min-width="60"
            >
            </el-table-column>

            <el-table-column
                prop="laycanStart"
                :label="$t('message.cargo.start_end_date')"
                min-width="170"
            >
              <template #default="scope">
                <i class="el-icon-time"></i>
                <span >{{ scope.row.laycan_start}} ~ {{scope.row.laycan_end}}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="loadingPortEname"
                :label="$t('message.cargo.loadingport')"
                min-width="100"
            >
              <template  #default="scope">
                <span>{{scope.row.loading_port}}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="discharge_port"
                :label="$t('message.cargo.dischargingport')"
                min-width="100"
            >
              <template  #default="scope">
                <span>{{scope.row.discharge_port}}</span>
              </template>
            </el-table-column>
<!--            <el-table-column-->
<!--                prop="sendtime"-->
<!--                :label="$t('message.email.sendtime')"-->
<!--                min-width="100"-->
<!--            >-->
<!--              <template #default="scope">-->
<!--                <span> {{editTime(scope.row.send_time, '{y}-{m}-{d} {h}:{i}:{s}')}}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                prop="subject"-->
<!--                :label="$t('message.email.mailtheme')"-->
<!--                min-width="70"-->
<!--            >-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                prop="sender"-->
<!--                :label="$t('message.email.sender')"-->
<!--                min-width="120"-->
<!--            >-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                prop="receiver"-->
<!--                :label="$t('message.email.recipient')"-->
<!--                min-width="120"-->
<!--            >-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                :label="$t('message.email.sendtime')"-->
<!--                min-width="100"-->
<!--            >-->
<!--              <template #default="scope">-->
<!--                <span> {{editTime(scope.row.send_time, '{y}-{m}-{d} {h}:{i}:{s}')}}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
          <!-- jx add 24.2.8 新增功能：按抽取时间排序 -->
            <el-table-column
              :label="$t('message.tonnage.createdTime')"
              :sortable="true"
              prop="create_time"
              min-width="80"
              :sort-method="SortTimeMethod"
            >
            <template #default="scope">
              {{getCreateTime(scope.row.create_time)}}
            </template>
            </el-table-column>
            <!-- // -->
            <el-table-column
                :label="$t('message.handle')"
                min-width="120">
              <template #default="scope">
                <div class="actions">
                  <el-button
                      size="mini" type="success"
                      @click.stop="goDetails(scope.row)" plain>{{$t('message.detail')}}
                  </el-button>
                  <el-button
                      size="mini"
                      :disabled="editdisabled"
                      @click.stop="go_edit(scope.row)">{{$t('message.edit')}}
                  </el-button>
                  <el-button
                      size="mini" type="danger"
                      :disabled="deletedisabled"
                      @click.stop="open_Del(scope.row.id)">{{$t('message.delete')}}
                  </el-button>
                </div>
              </template>
            </el-table-column>
            
          </el-table>
        </el-tab-pane>
      </el-tabs>
<!--      <div  class="list-footer">-->
<!--        <ul style="list-style: none">-->
<!--          <li><el-button size="small" @click="prePage">{{$t('message.email.lastpage')}}</el-button></li>-->
<!--          <li style="font-size:17px">{{pagination.current}}</li>-->
<!--          <li><el-button size="small" @click="nextPage">{{$t('message.email.nextpage')}}</el-button></li>-->
<!--          <li style="font-size:17px">共{{Math.ceil(pagination.total / 10)}}页</li>-->
<!--        </ul>-->
<!--      </div> :total="Math.ceil(pagination.total / 10)"-->
      <div  class="list-footer">
        <el-pagination
            small
            :prev-text="$t('message.previous')"
            :next-text="$t('message.next')"
            layout="slot,prev, pager, next"
            background
            v-model:currentPage="currentPage1"
            :total="pagination.total"
            :page-size="pagination.pagesize"
            @current-change="currentChange"
        />
      </div>
<!--      <el-dialog
          :title="$t('message.email.modifyinformation')"
          v-model="deit_dialog"
          width="30%"
          :before-close="handleClose">
        <span>{{$t('message.email.sentence1')}}</span>
        <template #footer>
                <span class="dialog-footer">
                <el-button @click="deit_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_edit(editObj.id)">{{$t('message.email.ok')}}</el-button>
                </span>
        </template>
      </el-dialog>
      <el-dialog
          :title="$t('message.detail')"
          v-model="detail_dialog"
          width="30%"
          :before-close="handleClose">
        <span>{{$t('message.check_message')}}</span>
        <template #footer>
                <span class="dialog-footer">
                <el-button @click="detail_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_detail(detailObj.id)">{{$t('message.email.ok')}}</el-button>
                </span>
        </template>
      </el-dialog>-->
      <el-dialog
          :title="$t('message.email.deleteinformation')"
          v-model="del_dialog"
          width="30%"
          :before-close="handleClose">
        <span>{{$t('message.email.sentence3')}}</span>
        <template #footer>
                <span class="dialog-footer">
                <el-button @click="del_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_del(delObj)">{{$t('message.email.ok')}}</el-button>
                </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListCard from '../../components/list-card/ListCard.vue';
import StarBox from '../../components/star-box/StarBox'
import {
  deletecargoemail, searchemailbyid,
  cargoemailcard,
  cargoemailpage,
  updateemailbyid, tonnageemailpage,
} from "../../api/emailextract";
import axios from "axios";
import {getToken} from "../../utils/auth";
import {parseTime} from '../../utils/index'
export default {
  inject:['reload'],
  data() {
    const validatelaycanEnd = (rule, value, callback) => {
      if(this.emailcargoform.laycanStart>this.emailcargoform.laycanEnd){
        callback(new Error(window.vm.$t('message.enddatevalide')))
      }
      else{
        callback()
      }
    };
    const validatelaycanStart = (rule, value, callback) => {
      if(this.emailcargoform.laycanEnd!==''){
        if(this.emailcargoform.laycanStart>this.emailcargoform.laycanEnd){
          callback(new Error(window.vm.$t('message.startdatevalide')))
        }
        else{
          callback()
        }}
    };
    const validatequantityUp = (rule, value, callback) => {
      if (parseInt(this.emailcargoform.quantityDown)>parseInt(this.emailcargoform.quantityUp)||
          parseFloat(this.emailcargoform.quantityDown)>parseFloat(this.emailcargoform.quantityUp)){
        callback(new Error(window.vm.$t('message.maxmessage')))
      }
      else {
        callback()
      }
    };
    const validatequantityDown = (rule, value, callback) => {
      if(this.emailcargoform.quantityUp!==''){
        if (parseInt(this.emailcargoform.quantityDown)>parseInt(this.emailcargoform.quantityUp)||
            parseFloat(this.emailcargoform.quantityDown)>parseFloat(this.emailcargoform.quantityUp)){
          callback(new Error(window.vm.$t('message.minmessage')))
        }
        else {
          callback()
        }}
    };
    return {
      dwt_type:'',
      editdisabled:'',
      deletedisabled:'',
      detaildisabled:'',
      name:{
        cargo_name:'',
        loadingportName:'',
        dischargeportName:'',
      },
      emailcargoform:{
        type:'cargo',
        creator_id: localStorage.getItem('customerId'),
        cargo_name:'',
        languageCode:'',
        quantityUp:'',
        quantityDown:'',
        laycanStart:'',
        laycanEnd:'',
        sendDays:'',
        cargoNameId:'',
        loadingportName:'',
        dischargeportName:'',
        loadingPortId:'',
        dischargePortId:'',
      },
      pagination: {
        current: 1,
        page: 6,
        total: 0,
      },
      options: [{
        value: '0',
        label: 'Other'
      }, {
        value: '1',
        label: 'Small Handy'
      }, {
        value: '2',
        label: 'Handysize'
      }, {
        value: '3',
        label: 'Large Handy'
      }, {
        value: '4',
        label: 'Handy Max'
      },
        {
          value: '5',
          label: 'Super Max'
        },{
          value: '6',
          label: 'Ultra Max'
        },
        {
          value: '7',
          label: 'Pana Max'
        },
        {
          value: '8',
          label: 'Kamsar Max'
        },
        {
          value: '9',
          label: 'Post Panamax'
        },
        {
          value: '10',
          label: 'Afra Max'
        },
        {
          value: '11',
          label: 'Capesize'
        },
        {
          value: '12',
          label: 'VLCC'
        },
        {
          value: '13',
          label: 'ULCC'
        }
      ],
      options2:[
        {
          value: 5,
          label: '5'
        },
        {
          value: 10,
          label: '10'
        },
        {
          value: 15,
          label: '15'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 25,
          label: '25'
        },
        {
          value: 30,
          label: '30'
        },
        {
          value: 35,
          label: '35'
        },
        {
          value: 40,
          label: '40'
        },
        {
          value: 50,
          label: '50'
        },
       /* {
          value: 60,
          label: '60'
        },
        {
          value: 70,
          label: '70'
        },
        {
          value: 80,
          label: '80'
        },
        {
          value: 90,
          label: '90'
        },
        {
          value: 100,
          label: '100'
        },*/
      ],
      activeName:'table',
      tableData:[],
      starFontSize:'28px',
      editObj:{}, //点击编辑按钮，传入的编辑对象
      detailObj:{},
      delObj:{},
      deit_dialog :false,
      detail_dialog:false,
      del_dialog:false,
      currentPage1:1,
      intid:'',
      rules:{
        laycanStart:[{validator:validatelaycanStart,trigger: ['blur','change']}],
        laycanEnd:[{validator:validatelaycanEnd,trigger: ['blur','change']}],
        quantityUp:[{validator:validatequantityUp,trigger: ['blur','change']}],
        quantityDown:[{validator:validatequantityDown,trigger: ['blur','change']}],
      },
    }
  },
  computed:{
    ...mapGetters(['isMobile','permission','lang','cargonamegroup','portnamegroup'])
  },
  // activated(){
  //   this.searchtonnage();//获取列表数据的请求函数
  //   if (this.$route.meta.isBack) {
  //     // 这是详情页返回，可任意写自己需要处理的方法
  //     this.$route.meta.isBack = false // 重置详情页标识isBack
  //   }
  // },
  // activated() {
  //   if (!this.$route.meta.isBack) {
  //     this.tableData = [];
  //     //this.pageNum = 1;
  //     //this.getList();
  //   }
  //   this.$route.meta.isBack = false;
  // },
  // beforeRouteEnter(to, from, next) {
  //   console.log(to.path)
  //   console.log(from.path)
  //   var path=to.path.split('/')[1]+'/'+to.path.split('/')[2]
  //   console.log(path)
  //   if (path == "tonnage/addtonnage") {
  //     console.log('true')
  //     from.meta.keepAlive  = true;
  //   } else {
  //     from.meta.keepAlive  = false;
  //   }
  //   next();
  // },
  methods:{
     /*jx add 新增功能：按抽取时间排序********/ 
     getCreateTime(time){
      let date = new Date(time)
      return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
    },
    SortTimeMethod({ column, prop, order }) 
    {
      if (order === 'ascending') {
        // 顺序排序逻辑
        this.tableData.sort((a, b) => a[prop] - b[prop]);
      } else if (order === 'descending') {
        // 倒序排序逻辑
        this.tableData.sort((a, b) => b[prop] - a[prop]);
      }
    },
   /**********/
    sendDays(value){
      if(value<0){
        this.emailcargoform.sendDays=0}else{
        if(value>365){
          this.emailcargoform.sendDays=365;
          this.$message.warning(window.vm.$t('message.onlyyear'))
        }
      }
    },
    querySearchCargoName(queryString, callback){
      this.$store.commit('metadata/querySearchCargo',queryString)
      callback(this.cargonamegroup)
    },
    querySearchLoadPort(queryString, callback){
      this.$store.commit('metadata/querySearchPort', {"queryString":queryString})
      callback(this.portnamegroup)
    },
    querySearchDischargePort(queryString, callback){
      this.$store.commit('metadata/querySearchPort', {"queryString":queryString})
      callback(this.portnamegroup)
    },
    goDetails(row){
      this.intid= row.id
      this.$router.push({path:`/cargo/addcargo/${2}/${this.intid}`,
        query:{
         extract:true,
          email_detail:row.emaildetail_id
        }
      })
    },
  /*  go_detail(){
      var  detailId= [this.detailObj]
      searchemailbyid(detailId).then((res)=>{
        if(res.error_code==200){
          this.detail_dialog=false
          this.$router.push(`/cargo/addcargo/${2}/${detailId}`)
        }
      })
       },*/
    selectcargoname(item){
      if(this.lang == 'en'){
        this.emailcargoform.cargoNameId = item.id

      }else {
        this.emailcargoform.cargoNameId = item.id
      }
    },
    selectloadport(item){
      if(this.lang == 'en'){
        this.emailcargoform.loadingPortId = item.id
      }else {
        this.emailcargoform.loadingPortId = item.id
      }
    },
    selectdischargeport(item){
      if(this.lang == 'en'){
        this.emailcargoform.dischargePortId = item.id
      }else {
        this.emailcargoform.dischargePortId = item.id
      }
    },
  /*  go_edit(){
      var  editId= [this.editObj]
      updateemailbyid(editId).then((res)=>{
        if(res.error_code==200){
          this.deit_dialog=false
          this.$router.push(`/cargo/addcargo/${1}/${editId}`)
        }
      })
    },*/
    go_edit(row){
      //var  editId= [this.editObj]
      this.$router.push({path:`/cargo/addcargo/${1}/${row.id}`,
        query:{
          extract:true,
          email_detail:row.emaildetail_id
        } })
      //1编辑
    },
    go_del(){
      deletecargoemail(this.delObj).then(response =>{
        if(response.error_code==200){
          this.$message.success(window.vm.$t('message.cargo.deletedinfo'))
          this.del_dialog=false
          this.getCargoEmail()
        }
      })
    },
   /* open_Edit(editObj) {
      this.editObj = editObj
      this.deit_dialog = true
    },
    open_Detail(detailObj){
      this.detailObj = detailObj
      this.detail_dialog = true
    },*/
    open_Del(delObj){
      this.delObj = delObj
      this.del_dialog = true
    },
    editTime(time,format){
      return parseTime(time,format)
    },
    typechange(obj){
      if ( this.options[obj].label == 'Other'){
        this.emailcargoform.quantityDown=null
        this.emailcargoform.quantityUp= null
      }
      else if ( this.options[obj].label == 'Small Handy'){
        this.emailcargoform.quantityDown= 0
        this.emailcargoform.quantityUp= 10000
      }
      else if ( this.options[obj].label == 'Handysize'){
        this.emailcargoform.quantityDown= 10000
        this.emailcargoform.quantityUp= 40000
      }
      else if(this.options[obj].label == 'Large Handy'){
        this.emailcargoform.quantityDown= 30000
        this.emailcargoform.quantityUp= 39999
      }
      else if(this.options[obj].label == 'Handy Max'){
        this.emailcargoform.quantityDown= 40000
        this.emailcargoform.quantityUp= 50000
      }else if(this.options[obj].label == 'Super Max'){
        this.emailcargoform.quantityDown= 50000
        this.emailcargoform.quantityUp= 59999
      }
      else if(this.options[obj].label == 'Ultra Max'){
        this.emailcargoform.quantityDown= 60000
        this.emailcargoform.quantityUp= 64999
      }
      else if(this.options[obj].label == 'Pana Max'){
        this.emailcargoform.quantityDown= 65000
        this.emailcargoform.quantityUp= 79999
      }
      else if(this.options[obj].label == 'Kamsar Max'){
        this.emailcargoform.quantityDown= 80000
        this.emailcargoform.quantityUp= 89999
      }
      else if(this.options[obj].label == 'Post Panamax'){
        this.emailcargoform.quantityDown= 90000
        this.emailcargoform.quantityUp= 99999
      }
      else if(this.options[obj].label == 'Afra Max'){
        this.emailcargoform.quantityDown= 80000
        this.emailcargoform.quantityUp= 110000
      }
      else if(this.options[obj].label == 'Capesize'){
        this.emailcargoform.quantityDown= 100000
        this.emailcargoform.quantityUp= 450000
      }
      else if(this.options[obj].label == 'VLCC'){
        this.emailcargoform.quantityDown= 150000
        this.emailcargoform.quantityUp= 320000
      }else if(this.options[obj].label == 'ULCC'){
        this.emailcargoform.quantityDown= 320000
        this.emailcargoform.quantityUp= 550000
      }
    },
    dwtDownchange(obj){
      if(obj == 0 && this.emailcargoform.quantityUp== 10000){
        this.dwt_type = 'Small Handy'
      }else if (obj== 10000 && this.emailcargoform.quantityUp== 40000){
        this.dwt_type = 'Handysize'
      } else if( obj== 30000 && this.emailcargoform.quantityUp== 39999){
        this.dwt_type = 'Large Handy'
      } else if(obj== 40000 && this.emailcargoform.quantityUp== 50000){
        this.dwt_type = 'Handy Max'
      } else if(obj== 50000 && this.emailcargoform.quantityUp== 59999){
        this.dwt_type = 'Super Max'
      } else if(obj== 60000 && this.emailcargoform.quantityUp== 64999){
        this.dwt_type = 'Ultra Max'
      } else if(obj== 65000 && this.emailcargoform.quantityUp== 79999){
        this.dwt_type = 'Pana Max'
      } else if(obj== 80000&& this.emailcargoform.quantityUp== 89999){
        this.dwt_type = 'Kamsar Max'
      } else if(obj== 90000&& this.emailcargoform.quantityUp== 99999){
        this.dwt_type = 'Post Panamax'
      } else if( obj== 80000&& this.emailcargoform.quantityUp== 110000){
        this.dwt_type = 'Afra Max'
      } else if(obj== 100000&& this.emailcargoform.quantityUp== 450000){
        this.dwt_type = 'Capesize'
      }else if(obj== 150000&& this.emailcargoform.quantityUp== 320000){
        this.dwt_type = 'VLCC'
      }else if( obj== 320000&& this.emailcargoform.quantityUp== 550000){
        this.dwt_type = 'ULCC'
      }else{
        this.emailcargoform.quantityDown = obj
        this.dwt_type = 'other'
      }
    },
    dwtUpchange(obj){
      if(obj == 10000 && this.emailcargoform.quantityDown== 0){
        this.dwt_type = 'Small Handy'
      }else if (obj== 40000 && this.emailcargoform.quantityDown== 10000){
        this.dwt_type = 'Handysize'
      } else if( obj== 39999 && this.emailcargoform.quantityDown== 30000){
        this.dwt_type = 'Large Handy'
      } else if(obj== 50000 && this.emailcargoform.quantityDown== 40000){
        this.dwt_type = 'Handy Max'
      } else if(obj== 59999 && this.emailcargoform.quantityDown== 50000){
        this.dwt_type = 'Super Max'
      } else if(obj== 64999 && this.emailcargoform.quantityDown== 60000){
        this.dwt_type = 'Ultra Max'
      } else if(obj== 79999 && this.emailcargoform.quantityDown== 65000){
        this.dwt_type = 'Pana Max'
      } else if(obj== 89999&& this.emailcargoform.quantityDown== 80000){
        this.dwt_type = 'Kamsar Max'
      } else if(obj== 99999&& this.emailcargoform.quantityDown== 90000){
        this.dwt_type = 'Post Panamax'
      } else if( obj== 110000&& this.emailcargoform.quantityDown== 80000){
        this.dwt_type = 'Afra Max'
      } else if(obj== 450000&& this.emailcargoform.quantityDown== 100000){
        this.dwt_type = 'Capesize'
      }else if(obj== 320000&& this.emailcargoform.quantityDown== 150000){
        this.dwt_type= 'VLCC'
      }else if( obj== 550000&& this.emailcargoform.quantityDown== 320000){
        this.dwt_type = 'ULCC'
      }else{
        this.emailcargoform.quantityUp = obj
        this.dwt_type = 'other'
      }
    },
    //不点联想输入搜索
    reselectcargo(){
      if(this.cargonamegroup.length != 0){
        for(let i=0;i<this.cargonamegroup.length;i++) {
          if (this.cargonamegroup[i].value == this.emailcargoform.cargo_name) {
            this.selectcargoname(this.cargonamegroup[i]);
            break
          }
        }
      }
    },
    reselectloadport(){
      if(this.portnamegroup.length != 0){
        for(let i=0;i<this.portnamegroup.length;i++) {
          if (this.portnamegroup[i].value == this.emailcargoform.loadingportName) {
            this.selectloadport(this.portnamegroup[i])
            break
          }
        }
      }
    },
    reselectdischargeport(){
      if(this.portnamegroup.length != 0){
        for(let i=0;i<this.portnamegroup.length;i++) {
          if (this.portnamegroup[i].value == this.emailcargoform.dischargeportName) {
            this.selectdischargeport(this.portnamegroup[i])
            break
          }
        }
      }
    },
    //检索船舶动态信息
    getCargoEmail(){
      if(this.lang == 'en'){
        this.emailcargoform.languageCode = 2
      }else{
        this.emailcargoform.languageCode = 1
      }
      const params = {
        query: this.emailcargoform,
        pagination: this.pagination
      }
      cargoemailcard(params).then(response =>{
        if(response.error_code ==200 ){
          this.tableData=response.datas
          this.pagination.total = response.pagination.total
        }
      })
    },
    senderEmail(e){
      var value = e.data;
      if(value ==null)
        this.emailcargoform.sendername = '';
    },
    recipient(e){
      var value = e.data;
      if(value ==null)
        this.emailcargoform.recipient = '';
    },
    currentChange(n) {
      this.pagination.current = n
      this.getCargoEmail()
    },
    cargoName(e){
      var value = e.data;
      if(value ==null)
        this.resetcargoName();
    },
    loadingPortName(e){
      var value = e.data;
      if(value ==null)
        this.resetloadingPortName();
    },
    dischargePortName(e){
      var value = e.data;
      if(value ==null)
        this.resetdischargePortName();
    },
    resetcargoName(){
      this.emailcargoform.cargoNameId = "";
    },
    resetloadingPortName(){
      this.emailcargoform.loadingPortId = "";
    },
    resetdischargePortName(){
      this.emailcargoform.dischargePortId =  "";
    },
    resetForm () {
      this.reload()
    }

  },
  mounted(){
    this.getCargoEmail()

    this.buttonpermission = this.permission == null? localStorage.getItem('permission'):this.permission;
    if(this.buttonpermission.includes('21005')){
      this.searchdisabled = false;
    }
    else this.searchdisabled = true;
    if(this.buttonpermission.includes('21006')){
      this.editdisabled = false;
    }
    else this.editdisabled = true;
    if(this.buttonpermission.includes('21007')){
      this.deletedisabled = false;
    }
    else this.deletedisabled = true;
    if(this.buttonpermission.includes('31000')){
      this.matchdisabled = false;
    }
    else this.matchdisabled = true;
  }
 /* },
  created() {
    this.getCargoEmail()
  }*/
}
</script>

<style lang="scss">
.ss-tonnage{
  width:100%;
  height:100%;
  //background: pink;
  .search-ton{
    width:100%;
    heighT:100%;
    .ton-top{
      width:100%;
      //height:26%;
      //background: blue;
      .box-card{
        width:100%;
        height:100%;
        border:none;
        .el-card__body{
          width:100%;
          height:100%;
          padding:5px;
          .el-form{
            width:100%;
            height:100%;
            display: flex;
            .form-left{
              width:80%;
              height:100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              //background: pink;
              .el-form-item{
                margin-bottom: 12px;
                #ship_years{
                  margin-left: 20px;
                }
                .search_btn{
                  width:30%
                }
                .el-button+.el-button{
                  width:30%;
                  margin-left: 30%;
                }
              }
              #col_2{
                color:#fff;
              }
            }
            .form-right{
              flex:1;
              background: pink;
            }
          }
        }
      }
    }
    .result_list{
      margin-top: 1%;
      width:100%;
      height:64%;
      position: relative;
      .el-tabs__header{
        margin:0;
      }
      .el-tabs__content{
        width:100%;
        height:85%;
        //background: brown;
        overflow-y: auto;
        #pane-card{
          width:100%;
          height:100%;
          .list-area{
            width:100%;
            height:100%;
            display: flex;
            flex-wrap: wrap;
            .list-item:hover{
              cursor: pointer;
            }
            .list-item{
              width:30%;
              height:220px;
              margin-bottom: 15px;
              margin-right: 2%;
              // background: #409EeF;
            }
          }

        }
        #pane-table{
          width:100%;
          height:100%;
          .el-table{
            margin-bottom: 30px;
            .actions{
              display: flex;
              justify-content: space-between;
              .actions-box{
                flex:1;
                display: flex;
                justify-content: space-around;
                .el-button--mini{
                  padding:3px 7px;
                }
              }
              .collect:hover{
                cursor: pointer;
              }
              .collect{
                width:28px;
                height:28px;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .list-footer{
      width:100%;
      display: flex;
      justify-content: center;
      ul,li{
        float: left;
        margin:0px;
        padding: 0px;
      }
      #tiao{
        margin-right: 30px;
      }
    }
  }
  .search-ton-mobile{
    width:100%;
    heighT:100%;
    .ton-top{
      width:100%;
      height:46%;
      //background: blue;
      .box-card{
        width:100%;
        height: 100%;
        .el-card__body{
          width:100%;
          height:100%;
          padding:2px;
          .el-form{
            width:100%;
            height:100%;
            //display: flex;
            #vessel_name{
              margin-top: 1.5%;
            }
            .el-form-item{
              margin-bottom: 10px;
              #ship_years{
                margin-left: 20px;
              }
              .el-button+.el-button{
                margin-left: 25%;
              }
            }
            #col_2{
              color:#fff;
            }
          }
        }
      }
    }
    h2{
      display: flex;
      justify-content: center;
      margin-top: 5%;
    }
    .list-area-mobile{
      width:96%;
      //height:90%;
      margin-left: 3.5%;
      display: flex;
      flex-wrap: wrap;
      //background: #aaa;
      overflow-y: hidden;
      .list-item:hover{
        cursor: pointer;
      }
      .list-item{
        width:100%;
        margin-bottom: 15px;
        margin-right: 5%;
        // background: #409EeF;
      }
    }
    // .result_list{
    //     margin-top: 1%;
    //      width:100%;
    //      height:70%;
    //      .el-tabs__content{
    //          width:100%;
    //          height:85%;
    //          //background: brown;
    //          overflow-y: auto;
    //          #pane-card{
    //              width:100%;
    //              height:100%;
    //               .list-area{
    //                 width:100%;
    //                 height:100%;
    //                 display: flex;
    //                 flex-wrap: wrap;
    //                 .list-item:hover{
    //                     cursor: pointer;
    //                 }
    //                 .list-item{
    //                     width:30%;
    //                     //height:100px;
    //                     margin-bottom: 15px;
    //                     margin-right: 2%;
    //                 // background: #409EeF;
    //                 }
    //              }

    //          }
    //          #pane-table{
    //              width:100%;
    //              height:100%;
    //              .el-table{
    //                 margin-bottom: 30px;
    //                 .actions{
    //                     display: flex;
    //                     justify-content: space-between;
    //                     .collect:hover{
    //                         cursor: pointer;
    //                     }
    //                     .collect{
    //                         width:28px;
    //                         height:28px;
    //                         margin-left: 10px;
    //                         display: flex;
    //                         justify-content: center;
    //                         align-items: center;
    //                     }
    //                 }
    //             }
    //          }
    //      }
    // }
  }
}
</style>
